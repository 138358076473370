.o96-modal__button-well {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
}
.o96-modal__button-well--mobile {
    position: fixed;
    bottom: 2px;
    left: 2px;
    width: calc(100% - 4px);
    padding: 1.25rem;
    background-color: rgba(0, 0, 0, 0.85);
    border-top: 1px solid #333;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    flex-direction: column-reverse;
}

.o96-modal__button-well--mobile-three-button {
    .o96-modal__button-well--multiple-primary {
        display: flex;
        flex-direction: column-reverse;
        button {
            margin-bottom: 1rem;
        }
    }
}
