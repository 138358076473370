* {
  box-sizing: border-box;
}

ul,
li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav {
  display: flex;
}
