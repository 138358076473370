.o96-parallax__container {
  position: relative;
  overflow: hidden;
}

.o96-parallax__container--figz {
  background-color: hsl(45, 73%, 58%);
}

.o96-parallax__item {
  position: absolute;
}

.o96-grid-row--img-bullets,
.o96-grid-row--bullets-img {
  display: flex;
  min-height: 200px;
  .mobile & {
    justify-content: center;
  }
}

.o96-grid-row--bullets-img {
  justify-content: flex-end;
}

.o96-grid-row--reorder-mobile-2 {
  .mobile & {
    order: 2;
  }
}

.o96-grid-row--reorder-mobile-1 {
  .mobile & {
    order: 1;
  }
}

.o96-container--hero-img {
  .mobile & {
    display: none;
  }
}

.o96-heading--hero {
  .mobile & {
    font-size: 1.5rem;
    padding: 0 1.5rem 0 1.5rem;
    font-weight: 400;
    padding-bottom: 1.5rem;
  }
  .tablet & {
    font-size: 2rem;
    padding: 0 0 0 1.5rem;
    font-weight: 400;
  }
}

.mobile {
  margin-top: 60px;
}

.o96-bullet-list__container {
  .mobile & {
    text-align: center;
  }
}

.o96-img--chart {
  height: 500px;
  top: -59px;
  left: -0px;
  .mobile & {
    height: 320px;
    left: 0;
  }
}
.o96-img--figures {
  height: 500px;
  top: -20px;
  left: -28px;
  .mobile & {
    height: 250px;
    top: 10px;
    left: -10px;
  }
}
.o96-img--tools {
  height: 390px;
  top: 10px;
  left: 10px;
  .mobile & {
    height: 250px;
    top: 10px;
    left: -5px;
  }
}
