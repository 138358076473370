.o96-grid-wrapper {
    height: calc(100vh - 12rem);
    width: 100%;
}

.o96-grid-wrapper--mobile {
    height: calc(100vh - 11rem);
}

.o96-grid-item--with-border-bottom {
    border-bottom: 1px solid #333;
}
