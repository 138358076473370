.o96-bg--yellow {
    background: rgb(245, 207, 71);
}

.o96-bg--cyan {
    background: rgb(0, 160, 223);
}

.o96-bg--space {
    background: url("../img/space.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: black;
}
