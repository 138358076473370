
:root {
    --amplify-font-family: 'Poppins', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    --amplify-text-xxs: 0.75rem;
    --amplify-text-xs: 0.81rem;
    --amplify-text-sm: 0.875rem;
    --amplify-text-md: 1rem;
    --amplify-text-md-sub: 1.15rem;
    --amplify-text-lg: 1.5rem;
    --amplify-text-xl: 2rem;
    --amplify-text-xxl: 2.5rem;
    --amplify-primary-color: #EAC02E;
    --amplify-primary-contrast: var(--amplify-black);
    --amplify-primary-tint: #EAC02E;
    --amplify-primary-shade: #e88b01;
    --amplify-secondary-color: var(--amplify-white);
    --amplify-secondary-contrast: var(--amplify-black);
    --amplify-secondary-tint: #31465f;
    --amplify-secondary-shade: #1F2A37;
    --amplify-tertiary-color: #5d8aff;
    --amplify-tertiary-contrast: var(--amplify-black);
    --amplify-tertiary-tint: #7da1ff;
    --amplify-tertiary-shade: #537BE5;
    --amplify-background-color: var(--amplify-black);
    --amplify-grey: #828282;
    --amplify-light-grey: #c4c4c4;
    --amplify-white: #ffffff;
    --amplify-smoke-white: #f5f5f5;
    --amplify-red: #dd3f5b;
    --amplify-blue: #099ac8;
    --amplify-black: #000000;
}