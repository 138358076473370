.o96-box--main {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    border: 1px solid #333;
}

.o96-box--bulk-add {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: scroll;
    height: calc(100vh - 160px);
    scrollbar-color: #333 #000;
}

.o96-collection__item {
    border-bottom: 1px solid #666;
}

.MuiDataGrid-root {
    border: 0 !important;
    border-radius: 0 !important;
}

.MuiDataGrid-toolbarContainer {
    border-bottom: 1px solid rgba(81, 81, 81, 1) !important;
}

* {
    &::-webkit-scrollbar {
        width: 14px;
        padding: 2px;
    }
    &::-webkit-scrollbar-track {
        background: #000;
        width: 14px;
    }
    &::-webkit-scrollbar-thumb {
        background: #333;
        border-radius: 1rem;
        border: 3px solid #000;
        margin-left: 2px;
        margin-right: 2px;
    }
    &::-webkit-scrollbar-corner {
        background: #000;
    }
}

.o96-modal__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 900px;
    width: 900px;
    background-color: black;
    border: 2px solid #ccc;
    border-radius: 1rem;
    padding: 1.5rem;

    &.o96-modal__container--mobile {
        width: 100%;
        height: 100%;
    }
}

.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within {
    outline: none !important;
}
