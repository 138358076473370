.o96-textfield {
    input.MuiInputBase-input {
        &[type="number"] {
            padding-right: 8.5px;
        }
    }
}
.o96-textfield--black {
    label.MuiFormLabel-root {
        color: black !important;
    }
    label.Mui-focused {
        color: black !important;
    }
    input.MuiInputBase-input {
        color: black !important;
    }
    .MuiInput-underline:after {
        border-bottom-color: black !important;
    }
    &.MuiOutlinedInput-root {
        fieldset {
            border-color: rgba(0, 0, 0, 0.23) !important;
        }
        &:hover fieldset {
            border-color: rgba(0, 0, 0, 1) !important;
        }
        &.Mui-focused fieldset {
            border-color: rgba(0, 0, 0, 1) !important;
        }
    }
}
