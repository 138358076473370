.o96-side-bar__content {
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  h6 {
    margin: 0;
    padding: 0;
    font-weight: 600;
  }
  label,
  span {
    font-size: 1.25rem;
    font-weight: 200;
  }
}

.o96-side-bar__two-col {
  display: flex;
  flex-direction: row;

  .o96-side-bar__content {
    width: 50%;
  }
}
