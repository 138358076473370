.o96-footer {
  color: #ccc;
  z-index: 200;
  width: 100%;
  display: flex;
  font-weight: 500;
  align-items: center;
  font-size: 0.8125rem;
  bottom: 0;
  position: relative;
  justify-content: space-between;
  overflow: hidden;
  padding-bottom: 1rem;
}

.o96-footer--on-light {
  color: #333;
}

.o96-footer--centered {
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0.5rem;
}
