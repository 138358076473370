.o96-side-nav::-webkit-scrollbar {
  width: 0.5em;
}

.o96-side-nav::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}

.o96-side-nav::-webkit-scrollbar-thumb {
  background-color: #666;
}

.o96-side-nav {
  grid-area: side-nav;
  position: fixed;
  background-color: #1e1e1e;
  height: calc(100vh - 50px);
  justify-content: center;

  .MuiTabs-root.MuiTabs-vertical {
    width: 100%;
  }

  .MuiTab-root {
    border-bottom: 1px solid #444;
    align-items: flex-start;
    text-transform: none;
  }
}

.o96-side-nav__list {
  padding: 1.75rem;
  background: #1e1e1e;
}

.o96-side-nav {
  width: 270px;
}

.o96-side-nav__list-item {
  border-bottom: 1px solid #666;
  font-size: 14px;
}

.o96-side-nav__list-link {
  font-weight: 200;
  padding: 0.5rem 0;
  display: block;

  &.selected {
    color: #eaba1e;
    box-shadow: inset -4px 0 0 0 #eaba1e;
  }
}

.o96-side-nav__list-heading {
  h5 {
    padding: 0 0 0.5rem 0;
    margin: 0;
    color: white;
    font-weight: 500;
  }
}
