.o96-header {
  .o96-home-page & {
    background: rgba(0, 0, 0, 0.8);
  }
  color: white;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 39px -10px rgba(0, 0, 0, 1);
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 3.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 1px solid #333;

  &.o96-header--mobile {
    padding-right: 0.5rem;
    overflow: visible;
    background: rgba(0, 0, 0, 0.95);

    & .o96-header__logo {
      padding-left: 1.25rem;
    }
  }
}
.o96-header__logo {
  background-color: black;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 3rem 0 2rem;
  height: inherit;
  z-index: 300;
  @media (max-width: 576px) {
    & {
      display: flex;
      padding-right: 2rem;
      height: auto;
    }
  }
}

.o96-header__logo-divider {
  width: 112px;
  height: 47px;
  border-bottom: 8px solid #eac02e;
  transform: translateY(10px) translateX(5px) rotate(-70deg);
  position: absolute;
  right: -42px;
  z-index: 400;
  background: black;
  @media (max-width: 576px) {
    & {
      display: none;
    }
  }
}

.o96-header__logo-img {
  width: 12rem;
  height: auto;
  display: block;
  @media (max-width: 576px) {
    & {
      display: block;
    }
  }
}
.o96-header__nav {
  display: flex;
  @media (max-width: 576px) {
    & {
      display: none;
    }
  }
}
