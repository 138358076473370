.o96-figure__container {
  --border-radius: 80px;
  padding: 2rem 2rem 0.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  min-height: calc(100vh - 126px);
  height: auto;

  &.o96-figure__container--mobile {
    --border-radius: 30px;
  }
}

.o96-figure__frame {
  padding-bottom: 1rem;
  border-radius: 40px;
  width: 100%;
  position: relative;
  .o96-figure__container--mobile & {
    border-radius: 18px;
  }
}

.o96-figure__360-controls {
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 200px;
  margin: 0 auto;
}

.o96-figure__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  margin: 1rem 0 0.5rem;
}

.o96-figure__list-item-image {
  width: 9rem;
  height: auto;
}

.o96-figure__frame-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 8px;
}

.o96-figure__border--outer,
.o96-figure__border--outer2 {
  background: linear-gradient(
    -45deg,
    #cfccbf 0%,
    #a0a698 2.83%,
    #8a967d 4.25%,
    #e1d9ce 5.03%,
    #e1d9ce 6.86%,
    #797669 7.26%,
    #535245 7.57%,
    #535245 8.01%,
    #838c7e 9.17%,
    #97a496 10.29%,
    #ddd6cc 13.89%,
    #d5cfc6 25.37%,
    #aeaca8 28.18%,
    #524f50 28.67%,
    #a09f8f 31.07%,
    #a2a587 33.28%,
    #e1dad1 36.04%,
    #bdbdb2 37.73%,
    #b6b5ac 37.96%,
    #6e7a66 39.1%,
    #84867a 40.26%,
    #d8d6c9 42.23%,
    #cccabe 45.72%,
    #7c7b6c 48.5%,
    #7c7b6c 49.21%,
    #cccabe 51.97%,
    #cccabe 53.5%,
    #899e90 56.15%,
    #cccabe 56.84%,
    #857f65 58.36%,
    #6b6651 59.94%,
    #cec5c0 62.95%,
    #abafaa 64.71%,
    #d0cfc3 68.25%,
    #a39888 76.88%,
    #d9d1c8 77.62%,
    #b1b4a1 83.21%,
    #838379 83.87%,
    #b59472 85.66%,
    #4b4543 89.48%,
    #454239 90.77%,
    #ccc5ba 92.65%,
    #99a199 97.63%,
    #d4cec3 100%
  );
  // height: 600px;
  display: flex;
  margin: 0 auto;
  border-radius: var(--border-radius);
  // width: 800px;
  align-items: stretch;
  justify-content: stretch;
}

.o96-figure__border--outer2 {
  height: initial;
  width: initial;
  flex-grow: 1;
  border-radius: calc(var(--border-radius) - 20px);
  background-size: 110%;
  background-position-x: -40px;
  .o96-figure__container--mobile & {
    border-radius: calc(var(--border-radius) - 6px);
  }
}

.o96-figure__border--inner,
.o96-figure__border--inner2 {
  background: black;
  border-radius: calc(var(--border-radius) - 10px);
  margin: 10px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-grow: 1;
  padding: 10px;

  .o96-figure__container--mobile & {
    margin: 6px;
    padding: 6px;
    border-radius: calc(var(--border-radius) - 2px);
  }
}

.o96-figure__border--inner2 {
  border-radius: calc(var(--border-radius) - 30px);
  .o96-figure__container--mobile & {
    border-radius: calc(var(--border-radius) - 6px);
  }
}

.o96-figure__container--banner {
  align-items: flex-start;
  position: relative;
  min-height: 350px;
  overflow: hidden;
  .o96-figure__border--outer {
    transform: rotate(15deg);
    margin: 0;
    position: absolute;
    top: -8px;
  }
  .o96-figure__frame {
    padding: 1.5rem;
  }
}
