.o96-pill-heading {
    font-weight: 300;
    padding: 0.25rem 1.25rem;
    background: #dbdbdb;
    border-radius: 40px;
    display: inline-block;
    margin: 0 0 1.5rem 0;
}
.o96-pill-heading__swatch {
    padding: 0.25rem 1.25rem;
    text-align: center;
}

.o96-heading--with-subtitle {
    flex-grow: 1;
}

.o96-heading--content--main {
    text-align: left;
    font-weight: 300;
}
.o96-h1-tooltip-link {
    color: rgba(0, 0, 0, 1) !important;
    text-decoration-style: dashed !important;
    text-decoration-color: rgba(0, 0, 0, 1) !important;
}
