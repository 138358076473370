html,
body,
#root {
    height: 100%;
}
.o96-body {
    padding-top: 50px;
    height: 100%;
    &--grid {
        display: grid;
    }
    &--mobile {
        padding-top: 28px;
    }
}

.o96-body--with-footer {
    min-height: calc(100vh - 46px);
    height: auto;
    margin-bottom: 0.5rem;
}

.o96-body--with-centered-footer {
    min-height: calc(100vh - 68px);
    height: auto;
}

.o96-main {
    grid-area: main;
}
.o96-side {
    background: #1e1e1e;
    padding: 1rem;
    color: white;
    width: 400px;
    display: block;
}
.o96-side-bar {
    grid-area: side-bar;
    background-color: #1e1e1e;
    color: white;
    padding: 2rem;
    text-align: center;
}
.o96-body--3-col {
    grid-template-columns: 270px auto 400px;
    grid-template-areas: "side-nav main side-bar";

    @media (max-width: 576px) {
        & {
            grid-template-columns: auto;
            grid-template-areas: "main";
        }
    }
}

.o96-body--2-col {
    grid-template-columns: 270px auto;
    grid-template-areas: "side-nav main";
}

@media (max-width: 576px) {
    .o96-side-nav,
    .o96-side-bar {
        display: none;
    }
}

.o96-mobile-button-well {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.85);
    border-top: 1px solid #333;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 9999;
}

.o96-main--with-bottom-well .o96-footer {
    padding-bottom: 100px;
}
