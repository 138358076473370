.o96-button {
  background-color: blue;
  padding: 12px 20px;
  color: white;
}

.o96-button--icon-only {
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;

  &:focus {
    outline: none;
  }
  svg {
    width: 24px;
    height: 24px;
  }
}

.MuiButton-containedWhite {
  color: black !important;
}
